<template>
  <div class="interview-chat">
    <div class="chat-head">
      <div class="head-left">
        <el-image class="head-icon" :src="require(`../../../assets/image/interview.png`)" fit="contain"></el-image>
        <div class="head-title">AI模拟面试</div>
      </div>
      <el-image class="head-right" @click="sendExit" :src="require(`../../../assets/image/exit2.png`)" fit="contain"></el-image>
    </div>
    <div class="chat-main">
      <div class="main-head">
        <div class="head-title">
          <span>招聘类型</span>
        </div>
        <div class="head-data">
          <el-select v-model="value" clearable placeholder="请选择" @change="cascaderChange" size="small">
            <el-option
              v-for="item in options"
              :key="item.interview_badge_tags_id"
              :label="item.badge_tags_title"
              :value="item.interview_badge_tags_id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="main-bottom">
        <div class="label" v-for="tag in tags" :key="tag.interview_badge_tags_id" @click="selectedTagId = tag.interview_badge_tags_id">
          <span class="label-title">{{ tag.badge_tags_title }}</span>
          <i class="el-icon-success" v-if="tag.interview_badge_tags_id === selectedTagId"></i>
        </div>
      </div>
      <div class="main-button">
        <el-button class="produce-button" @click="sendMessage">下一步</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getBadgeTags, getBadgeTagsChildren, getInterviewGPT } from '../../../request/api'

export default {
  data() {
    return {
      options: [],
      value: '',
      selectedTagId: '',
      tags: [],
    }
  },
  methods: {
    sendExit() {
      this.$emit('update-value')
    },
    async sendMessage() {
      if (this.selectedTagId === '') {
        return this.$message.warning('请先选择一个招聘类型');
      }

      const res = await getInterviewGPT(this.selectedTagId)
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }
      this.$router.push(
        {
          path: 'interviewDialogue',
          query: {
            interview_badge_tags_id: this.selectedTagId
          }
        }
      )
    },
    // 搜索
    async cascaderChange(interview_badge_tags_id) {
      if (interview_badge_tags_id) {
        try {
          const res = await getBadgeTagsChildren(interview_badge_tags_id)
          if (res.status !== 200 || res.data.code !== '1000') {
            this.$message.error(res.data.msg)
            return
          }

          if (res.data.data !== false) {
            this.tags = res.data.data
          }
        } catch(err) {
          this.$message.error('接口出错了', err);
        }
      }
    },
    async init() {
      const res = await getBadgeTags()
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }

      this.options = res.data.data
      this.value = res.data.data[0].interview_badge_tags_id
      this.cascaderChange(this.value)
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.interview-chat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .chat-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }
  .chat-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    .main-bottom {
      height: 50%;
      padding: 20px 0;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: flex-start;
      overflow-y: auto;
      .label {
        background-color: #fff;
        height: 34px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #cce1ff;
        padding: 0 20px;
        cursor: pointer;
        margin-bottom: 20px;
        margin-right: 20px;
        .label-title {
          margin-right: 10px;
          font-size: 14px;
        }
        .el-icon-success {
          font-size: 20px;
          color: #4d94ff;
        }
      }
    }
    .main-button {
      flex: 1;
      .produce-button {
        width: 120px;
        color: #fff;
        font-weight: bold;
        background-image: linear-gradient(to right, #7763ea, #569be8);
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .1);
        border: none;
        &:hover {
          box-shadow: 0 6px 14px 0 rgba(0, 0, 0, .2);
        }
        &:active {
          box-shadow: inset 0 3px 4px rgba(0, 0, 0, .2);
        }
      }
    }
    .main-head {
      height: 20%;
      border-bottom: 1px solid #4d94ff;
      display: flex;
      flex-direction: column;
      .head-title {
        display: flex;
        align-items: center;
        height: 20%;
        font-weight: bold;
        color: #4d94ff;
      }
      .head-data {
        flex: 1;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>